.home-sections {
  display: flex;

  @include mobileMax {
    flex-direction: column;
  }
}

.left-section {
  width: 100%;
}

.right-section {
  width: 400px;
  position: relative;
  display: block;

  @include mobileMax {
    width: 100%;
  }
}

.right-section-wrapper {
  display: block;
  position: sticky;
  top: 55px;
}

.home-page-image {
  width: 100%;
  height: auto;
}

.home-links-lister {
  display: flex;
  flex-direction: column;
}

.home-link {
  margin: 5px;
  display: flex;
  align-items: center;
  color: $colorBlack;
  padding: 5px 10px;
  background-color: $colorOrange3;
  border-radius: 5px;
  width: calc(100% - 10px);
  box-sizing: border-box;
  justify-content: center;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: $colorYellow;
  }

  svg {
    margin: 0 5px;
  }

  &.fb {
    background-color: rgba(royalblue, 0.75);
    color: $colorWhiteDirty;

    img {
      height: 35px;
      width: auto;
      transition: transform 0.75s ease-in-out;
    }

    &:hover {
      background-color: rgba(royalblue, 0.5);
      color: $colorBlack;

      img {
        transform: rotateY(360deg);
      }
    }
  }
}

.home-page-slider-2 {
  position: relative;
  width: 98%;
  margin: 0 1%;
}

.button-contact-us {
  margin: 10px;
  height: 30px;
  background-color: $colorOrange3;
  display: flex;
  justify-content: center;
  padding: 5px 0 0 0;
  border-radius: 5px;
  color: $colorBlack;
  cursor: pointer;

  a {
    color: $colorBlack;
  }

  &:hover {
    background-color: $colorYellow;
  }
}