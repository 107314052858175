.site-wrapper {
  display: flex;
  flex-direction: column;
}

.site-section {
  width: 95%;
  margin: 0 2.5%;

  @include largeDesktopMin {
    width: 1200px;
    margin: 0 calc(50vw - 600px);
  }
}

.banner {
  width: 100%;
  height: auto;

  @include tabletMax {
    margin-top: 50px;
  }
}

.site-title {
  font-family: PoppinsLight;
  font-size: 52px;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid $colorBlack;
  color: $colorBlack;
  margin-top: 7px;

  @include mobileMax {
    font-size: 28px;
  }
}

.sides-wrapper {
  position: relative;
  display: flex;

  @include tabletMax {
    flex-direction: column;
  }
}

.left-side {
  width: 50%;

  @include tabletMax {
    width: 100%;
  }
}

.right-side-wrapper {
  width: 50%;
  justify-content: center;
  flex-direction: column;

  @include tabletMax {
    width: 100%;
  }
}

.right-side {
  justify-content: center;
  flex-direction: column;
  display: flex;
  position: sticky;
  top: 60px;

  & > * {
    margin: 0 auto;
  }
}

.quote {
  color: $colorBlue3;
  font-size: 24px;
  text-align: center;
  margin: 20px 0;
  font-style: italic;
  width: 95%;
  padding: 0 2.5%;
}

.quote-big-wrapper {
  position: relative;
  max-width: 1200px;
  padding: 0 calc(50vw - 600px);
  background-color: $colorBlue1;

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("/big-quote.jpg");
    background-position: center 25%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    opacity: 0.2;

    @include mobileMax {
      background-size: 100% 100%;
    }
  }
}

.quote-big {
  font-size: 24px;
  text-align: center;
  padding: 20px 20px 15px;
  font-family: PoppinsLight;

  & b {
    font-size: 26px;
    animation: goldenOutline 2s infinite;
  }

  @include mobileMax {
    font-size: 18px;

    & b {
      font-size: 20px;
      animation: goldenOutline 2s infinite;
    }
  }
}

.paragraph {
  margin: 5px;
  font-size: 15px;
  font-family: PoppinsLight;
  text-align: justify;

  & b {
    font-size: 16px;
  }
}

.slider-wrapper {
  position: relative;

  @include mobileMax {
    margin-top: 50px;
  }
}

.arrow {
  position: absolute;
  height: 100%;
  width: 50px;
  display: flex;
  font-size: 40px;
  top: 0;
  align-items: center;
  cursor: pointer;
  color: transparent;
  //A
  transition: color 0.25s;

  &:hover {
    color: $colorOrange3;
  }

  &.left {
    left: -15px;
    transform: scale(-1, 1);
  }

  &.right {
    right: -15px;
  }

  @include tabletMax {
    color: $colorOrange3;
    top: -68px;
    align-items: flex-start;
  }

  @include mobileMax {
    top: -45px;
    font-size: 30px;
  }
}

.slider-image {
  width: 100vw;
  height: auto;
}

.other-wrapper {
  text-align: justify;
  font-weight: bold;
  margin: 10px;
}

.para-wrapper {
  width: 100%;
  display: flex;
  text-align: left;

  img {
    width: 50%;
  }

  p {
    text-align: justify;
    font-weight: bold;
    margin: auto;
    padding: 10px;
    box-sizing: border-box;
    width: 50%;
  }

  &.left {
    flex-direction: row-reverse;
  }

  @include mobileMax {
    flex-direction: column-reverse;
    &.left {
      flex-direction: column-reverse;
    }

    p,
    img {
      width: 100%;
    }
  }
}