.slider-item {
  width: 100%;
  height: auto;

  img.g {
    width: 60%;
    height: auto;
    margin: 0 20%;

    @include mobileMax {
      width: 100%;
      margin: 0;
    }
  }

  img.h {
    width: 100%;
    height: auto;
  }
}