.footer {
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  background-color: $colorDarkGray;
  padding: 20px 0;

  @include mobileMax {
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
  }
}

.footer-item {
  width: 33%;
  display: flex;
  justify-content: center;
  max-width: 400px;

  @include mobileMax {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;
  }
}

.footer-item-text {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: $colorGray;

  b {
    font-size: 16px;
    color: $colorWhite;
  }

  a {
    font-size: 14px;
    color: $colorGray;
  }
}

.footer-item-icon {
  margin: 0 0 auto 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $colorWhite;
  border-radius: 50%;
  border: 2px solid $colorWhite;
  min-height: 60px;
  min-width: 60px;
  height: 60px;
  width: 60px;

  svg {
    font-size: 40px;
  }
}