$colorBlack: #000;
$colorRoyalBlue: #4169e1;
$colorDarkBlue: #1e222b;
$colorDarkerBlue: #111218;
$colorLightDarkBlue: #4b505d;
$colorGold: #976508;
$colorGray: #888;
$colorDarkGray: #272727;
$colorGreen: #09b83e;
$colorLightBlue: #add8e6;
$colorOrange: #ff8c00;
$colorOrange2: #bca480;
$colorOrange3: #f8aa00;
$colorRed: #d62929;
$colorSilver: #c0c0c0;
$colorTransparent: rgba(0, 0, 0, 0);
$colorWhite: #fff;
$colorWhiteDirty: #f5f5f5;
$colorYellow: #eae000;
$colorBlue1: #848cc8;
$colorBlue2: #5861a9;
$colorBlue3: #3c4694;
$colorBlue4: #26307f;
$colorBlue5: #26307f;

@mixin textShadow($color) {
  text-shadow: $color 1px 0 0, $color 0.540302px 0.841471px 0, $color -0.416147px 0.909297px 0,
  $color -0.989992px 0.14112px 0, $color -0.653644px -0.756802px 0, $color 0.283662px -0.958924px 0,
  $color 0.96017px -0.279415px 0;
}
